import AOS from "aos";
import "aos/dist/aos.css";

export default function fnAnimateOnScroll() {
   AOS.init({
      offset: 50,
      duration: 600,
      easing: "ease",
      once: true
   });
}
