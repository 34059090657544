import fnLazyLoad from "./scripts/lazyLoad";
import fnInitCarousels from "./scripts/carousels";
import fnAnimateOnScroll from "./scripts/aos";
import fnNavbarMobile from "./scripts/navbar";

const init = () => {
    fnLazyLoad();
    fnInitCarousels();
    fnAnimateOnScroll();
    fnNavbarMobile();
};

addEventListener("page:loaded", init);

