import Swiper, { Autoplay,} from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const bannerCarousel = () => {
   new Swiper(".gallery-carousel", {
      spaceBetween: 7,
      modules: [Autoplay],
      speed: 800,
      touchRatio: 0.3,
      autoplay: {
         delay: 2000,
         disableOnInteraction: true
      },
      breakpoints: {
         1400: {
            slidesPerView: 3.4,
         },
         1023: {
            slidesPerView: 2.3,
         },
         576: {
            slidesPerView: 1.9,
         },
         0: {
            slidesPerView: 1,
         }
      }
   });
};

export default function fnInitCarousels() {
   bannerCarousel();
}
