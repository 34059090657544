export default function fnNavbarMobile() {
   const body = document.body;
   const navbar = document.querySelector(".page-navbar");
   const navbarMenuLinks = document.querySelectorAll(".page-navbar .navbar-menu .menu-link");
   const navbarContentMenuIcon = document.querySelector(".navbar-menu-icon");

   if (window.innerWidth <= 1024) {
      const menuOpen = () => {
         body.style.overflow = "hidden";
         navbar.classList.add("navbar-expanded");
      };

      const menuClose = () => {
         body.style.overflow = "";
         navbar.classList.remove("navbar-expanded");
      };

      navbarContentMenuIcon.onclick = () => {
         navbar.classList.contains("navbar-expanded") ? menuClose() : menuOpen();
      };

      navbarMenuLinks.forEach((link, index) => {
         link.onclick = () => {
            navbar.classList.contains("navbar-expanded") ? menuClose() : menuOpen();
         };
      });
   }

   // Get all sections that have an ID defined
   const sections = document.querySelectorAll(".highlight-section");
   const menuLinks = document.querySelectorAll(".menu-link");

   if (sections.length > 0) {
      // Add an event listener listening for scroll
      window.addEventListener("scroll", navHighlighter);
      window.addEventListener("resize", navHighlighter);
   }

   function navHighlighter() {
      // Get current scroll position
      let scrollY = window.pageYOffset;

      if (sections.length > 0) {
         // Now we loop through sections to get height, top and ID values for each
         sections.forEach((current, index) => {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - 50;


            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
               menuLinks[index].classList.add("menu-link-active");
            } else {
               menuLinks[index].classList.remove("menu-link-active");
            }
         });
      }
   }

   addEventListener("page:unload", function() {
      removeEventListener("scroll", navHighlighter);
      removeEventListener("resize", navHighlighter);
   }, { once: true });
}


